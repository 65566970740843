import React from 'react';
import { NumericFormat } from 'react-number-format';
import './styles.css'; // Importa o arquivo de estilos genérico

export const Rate = (props) => {

    const handleClear = () => {
        props.onChange(0); // Define o valor como 0,00
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' && props.value === "0") {
            e.preventDefault(); // Previne que o último dígito "0" não seja apagado incorretamente
        }
    };

    return (

        <div className="d-flex align-items-center input-wrapper my-1 input-rate me-2">
            <div className='mx-1 input-label'>{props.label}</div>
            <div className="position-relative input-field-wrappeer ">
                <NumericFormat
                    // style={{ maxWidth: '80px' }} // Define o tamanho máximo para caber "100,00 %"

                    id={props.id}
                    className="form-control input-field"
                    value={props.value}
                    onValueChange={(values) => {
                        const { floatValue } = values;
                        props.onChange(floatValue);
                    }}
                    onKeyDown={handleKeyDown}
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isAllowed={(values) => {
                        const { floatValue } = values;
                        return floatValue >= 0 && floatValue <= 100; // Limita entre 0 e 100
                    }}
                    thousandSeparator='.'
                    decimalSeparator=','
                    readOnly={props.readOnly}
                    disabled={props.readOnly}
                    inputMode="decimal"
                />
                <button
                    type="button"
                    onClick={handleClear}
                    className="clear-button"
                    aria-label="Clear input"
                >
                    &#x2715;
                </button>
            </div>
        </div>

    );
};
