import React from 'react';

export const Loading = ({ enabled = true, text = "Carregando..." }) => {
    if (!enabled) return null;

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="text-center">
                <i className="fas fa-spinner fa-spin fa-3x text-primary"></i>
                <p className="mt-3 fs-4">{text}</p>
            </div>
        </div>
    );
};
