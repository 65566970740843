import React, { useState, useEffect } from 'react';
import { MainContainer } from './Container';
import { Value } from './inputs/Value';
import { Rate } from './inputs/Rate';

const HeaderCad = () => {
    return <>
        <div className="line-white w-100"></div>
        <div className="line-red w-100"></div>
    </>
}

const HeaderBrl = () => {
    return <>
        <div className="line-green w-100"></div>
        <div className="line-yellow w-100"></div>
    </>
}

export const Calc = ({ cadBRLValue, lastUpdateValue }) => {
    const [cadValue, setCadValue] = useState();

    const [totalCadValue, setTotalCadValue] = useState('');
    const [brlValue, setBrlValue] = useState('');

    const [gstRate, setGstRate] = useState(5); // Default: 5%
    const [pstRate, setPstRate] = useState(6); // Default: 6%
    const [iofRate, setIofRate] = useState(1.1); // Default: 1.1%
    const [wizeRate, setWizeRate] = useState(0.77); // Default: 0.77%

    const [gstValue, setGstValue] = useState(0); // Valor calculado do GST
    const [pstValue, setPstValue] = useState(0); // Valor calculado do PST

    const [iofValue, setIofValue] = useState(0); // Valor calculado do IOF
    const [wizeValue, setWizeValue] = useState(0); // Valor calculado da Wize

    const [brlTotalValue, setBrlTotalValue] = useState(0); // Valor calculado da Wize

    const calc = () => {
        // Ensure all numerical values are treated correctly
        const cadValueNum = parseFloat(cadValue) || 0;
        const gstRateNum = parseFloat(gstRate) || 0;
        const pstRateNum = parseFloat(pstRate) || 0;
        const cadBRLValueNum = parseFloat(cadBRLValue) || 0;
        const iofRateNum = parseFloat(iofRate) || 0;
        const wizeRateNum = parseFloat(wizeRate) || 0;

        // Calculate GST and PST values based on editable rates
        const gstValueCalc = parseFloat((cadValueNum * (gstRateNum / 100)));
        const pstValueCalc = parseFloat((cadValueNum * (pstRateNum / 100)));

        // Update the total in CAD
        const totalCad = cadValueNum + gstValueCalc + pstValueCalc;
        setTotalCadValue(totalCad);

        // Calculate the total in BRL before IOF and Wise
        const totalBrlBeforeTaxes = (totalCad * cadBRLValueNum);
        setBrlValue(totalBrlBeforeTaxes);

        // Calculate IOF and Wise
        const iof = (parseFloat(totalBrlBeforeTaxes) * (iofRateNum / 100));
        const wize = (parseFloat(totalBrlBeforeTaxes) * (wizeRateNum / 100));

        // Update the total BRL including IOF and Wise
        const totalBrl = (parseFloat(totalBrlBeforeTaxes) + parseFloat(iof) + parseFloat(wize));
        setBrlTotalValue(totalBrl)

        // Update the values of GST, PST, IOF, and Wise
        setGstValue(gstValueCalc);
        setPstValue(pstValueCalc);
        setIofValue(iof);
        setWizeValue(wize);
    };

    useEffect(() => calc(), [calc, cadValue, gstRate, pstRate, wizeRate, iofRate]);

    





    return (
        <MainContainer cadBRLValue={cadBRLValue} lastUpdateValue={lastUpdateValue}>

            {/* JSX CAD */}
            <HeaderCad />

            <Value label={"CAD"} id={"cadInput"} value={cadValue} onChange={setCadValue} showClear />

            {/* Row GST */}
            <div className="d-flex flex-row">
                <Rate label={"GST"} id={"gstRate"} value={gstRate} onChange={setGstRate} />
                <Value id={"gstValue"} value={gstValue} readOnly={"readOnly"} />
            </div>

            {/* Row PST */}
            <div className="d-flex flex-row">
                <Rate label={"PST"} id={"pstRate"} value={pstRate} onChange={setPstRate} />
                <Value id={"pstValue"} value={pstValue} readOnly={"readOnly"} />
            </div>

            <Value label={"Total"} id={"totalCadValue"} value={totalCadValue} readOnly={"readOnly"} />

            {/* JSX BRL */}
            <HeaderBrl />

            <Value label={"BRL"} id={"brlValue"} value={brlValue} readOnly={"readOnly"} className={"mt-0"} />

            {/* Row Wise */}
            <div className="d-flex flex-row">
                <Rate label={"Wise"} id={"wizeRate"} value={wizeRate} onChange={setWizeRate} />
                <Value id={"wizeValue"} value={wizeValue} readOnly={"readOnly"} />
            </div>

            {/* Row IOF */}

            <div className="d-flex flex-row">
                <Rate label={"IOF"} id={"iofRate"} value={iofRate} onChange={setIofRate} />
                <Value id={"iofValue"} value={iofValue} readOnly={"readOnly"} className={"flex-grow-1"} />
            </div>

            <Value label={"Total"} id={"brlTotalValue"} value={brlTotalValue} readOnly={"readOnly"} className={"mt-2 fw-bold"} />




        </MainContainer>
    );
};