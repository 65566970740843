import React from 'react'
import Header from './Header'
import Footer from './Footer'

export const MainContainer = ({ children, cadBRLValue, lastUpdateValue }) => {

    return <>
        <Header />
        <div className={`main-container`}>
            <div className="container px-4 py-0">
                <div className="row my-0 p-0">
                    {children}          
                </div>
            </div>
        </div>
        <Footer cadBRLValue={cadBRLValue} lastUpdateValue={lastUpdateValue} />
    </>
}