import React from 'react';
import { NumericFormat } from 'react-number-format';
import './styles.css'; // Importa o arquivo de estilos genérico

export const Value = (props) => {

    const handleClear = () => {
        if (props.onChange) {
            props.onChange('0.00'); // Define o valor como 0,00
        }
    };

    return (
        <div className="d-flex align-items-center input-wrapper my-1">
            {/* Verifica se o label existe antes de renderizar */}
            {props.label ? (
                <div className='mx-1 input-label'>{props.label}</div>
            ) : (
                <></>
            )}
            
            <div className="position-relative input-field-wrapper">
                <NumericFormat
                    className="form-control input-field"
                    id={props.id || null}
                    value={props.value}
                    onValueChange={(values) => {
                        const { value } = values;
                        if (props.onChange) {
                            props.onChange(value);
                        }
                    }}
                    thousandSeparator='.'
                    decimalSeparator=','
                    fixedDecimalScale={true}
                    decimalScale={2}
                    readOnly={props.readOnly}
                    disabled={props.readOnly}
                    inputMode="decimal"
                />
                {props.showClear && (
                    <button
                        type="button"
                        onClick={handleClear}
                        className="clear-button"
                        aria-label="Clear input"
                    >
                        &#x2715;
                    </button>
                )}
            </div>
        </div>
    );
};
