import React from 'react'

const Footer = ({ cadBRLValue, lastUpdateValue }) => {

  return (
    <footer className="mb-2 main-header">
      <div className="container themeTextColorPrimary fw-bold">
        <p className='m-0 p-0'>{`1,00 CAD = ${cadBRLValue} BRL`}</p>
        <p className='m-0 p-0 small'>{`Última atualização: ${lastUpdateValue}`}</p>
      </div>
    </footer>
  )
}

export default Footer
