import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '../assets/styles/App.css';
import { Calc } from '../components/Calc';
import { Loading } from '../components/Loading';
import { fetchData } from '../utils/datasource';

function App() {
  const [cadBRLValue, setCadBRLValue] = useState(null);
  const [lastUpdateValue, setLastUpdateValue] = useState('');
  const [loading, setLoading] = useState(true);

  const asyncFunction = async () => {
    try {
      console.log(`Obtendo cotação`)

      const response = await fetchData(`/last/CAD-BRL`, `GET`)
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();

        if (data && data.CADBRL) {
          const conversionRate = parseFloat(data.CADBRL.ask);
          setCadBRLValue(conversionRate);

          const currentDate = new Date();
          const formattedDate = currentDate.toLocaleDateString('pt-BR');
          const formattedTime = currentDate.toLocaleTimeString('pt-BR');
          setLastUpdateValue(`${formattedDate} ${formattedTime}`);
        }
      } else {
        throw new Error("Resposta não é JSON");
      }
    } catch (error) {
      console.error("Erro ao buscar a cotação:", error);
    } finally {
      setTimeout(() => setLoading(false), 1000)
    }
  };

  useEffect(() => {
    asyncFunction();
  }, []);

  return (
    <Router>
      {loading ? (
        <Loading enabled={loading} text="Carregando..." />
      ) : (
        <Calc cadBRLValue={cadBRLValue} lastUpdateValue={lastUpdateValue} />
      )}
    </Router>
  );
}

export default App;
