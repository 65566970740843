import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ headerData }) => {

  return <div className="container-fluid main-header fixed-top p-0 theme-header-border-color">
    <div className="d-flex justify-content-between themeTextColorPrimary p-0 px-2">
      <div>
        <h6 id="header-title" className="mb-0 fw-bold">CadCalc</h6>
        <h6 id="header-brand" className="p-0 m-0 small">by Sibe</h6>
      </div>

      <div className="p-2">
        <div>
          <button onClick={() => window.location.reload()} className='btn p-0 m-0'><i className="fas fa-redo-alt themeTextColorPrimary p-0 m-0"></i></button>
        </div>
      </div>
    </div>
  </div>
}

export default Header